.main{
  background-image: url(qrBack.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.qr {
    font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
    line-height: 1.5;
    font-weight: 400;
  
    color: rgba(20, 20, 20, 0.87);
  
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
  }
  
  
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  img {
    max-width: 100%;
    height: auto;
    margin-bottom: 1rem;
  }
  
  
  
  
  
  button {
    border-radius: 8px;
    border: 1px solid transparent;
    padding: 0.6em 1.2em;
    font-size: 1em;
    font-weight: 500;
    font-family: inherit;
    background-color: #b379df;
    cursor: pointer;
    transition: border-color 0.25s;
    padding: 0.5rem 1rem;
  }
  button:hover {
    border-color: #646cff;
  }
  button:focus,
  button:focus-visible {
    outline: 4px auto -webkit-focus-ring-color;
  }
  
  
  
  